footer {
    background-color: var(--background-secondary);
    padding: 2rem var(--container);
    position: relative;
    overflow: hidden;
}
footer:before,footer:after {
    content: '';
    display: block;
    position: absolute;
}
footer:before {
    width: 100px;
    height:100px;
    bottom: 0;
    right: 0;
    background-color: var(--primary);
    filter: blur(100px);
}
footer:after {
    width: 150px;
    height: 150px;
    background-color: var(--accent-color);
    bottom: -70px;
    left: -70px;
    filter: blur(150px);
}
footer .footer-content-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
footer .footer-content-container div {
    text-align: right;
}
footer .footer-content-container div h3{
    font-size: var(--fs-lg);
}
footer .footer-content-container div h3 span {
    color: var(--primary);
}
footer .footer-content-container div ul {
    list-style: none;
    padding-left: 0;
}
footer .footer-content-container div ul li {
    font-size: var(--fs-sm);
    margin: 0.7rem 0;
    position: relative;
    opacity: 0.8;
    transition: 0.3;
}
footer .footer-content-container div ul li:before {
    content: '';
    position: absolute;
    bottom: -5px;
    right: 0;
    width: 0%;
    height: 2px;
    display: block;
    transition: 0.2s;
    background-color: var(--primary);
}
footer .footer-content-container div ul li:hover {
    opacity: 0.9;
}
footer .footer-content-container div ul li:hover:before {
    width: 32%;
}
footer .footer-content-container div ul li:nth-child(odd):hover::before {
    width: 60%;
}

footer .copyright {
    padding: 2rem 0;
}
footer .copyright p {
    font-size: 0.8rem;
    opacity: 0.9;
}
footer .copyright p a:hover {
    color: var(--primary);
}

@media (max-width:768px){
    footer .copyright {
        text-align: center;
    }
}


footer .creator {
    padding: 1rem 0;
    padding-bottom: 0;
}
footer .creator p {
    font-size: 1.9rem;
    opacity: .9;
}
footer .creator p a {
    font-size: 1.9rem;
    transition: .2s;
}
footer .creator p a:hover {
    color: var(--primary);
}
