.lock-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
}
.lock-container .content{
    width: 300px;
    text-align: center;
}
.lock-container .content h3 {
    font-size: var(--fs-lg);
    margin: 0.7rem 0;
}
.lock-container .content h3 span {
    color: var(--primary);
}
.lock-container .content input {
    width: 100%;
    border:var(--border);
    outline:none;
    font-size:var(--fs-sm);
    background-color: transparent;
    border-radius: 4px;
    padding: 0.7rem;
    color: var(--font-color);
    font-family: mainAR !important;

}
.lock-container .content input:hover {
    border:var(--border-dark);
}
.lock-container .content input:focus {
    border-color: var(--primary);
}
.lock-container .content button {
    color: #f4f4f4;
    padding: 0.5rem 0.8rem;
    background-color: var(--primary);
    border-radius: 4px;
    border: none;
    outline: none;
    margin: 0.4rem 0;
    display: block;
    margin-left: auto;
}
.lock-container p {
    font-size: var(--paragraph);
    margin-top: 1rem;
    opacity: .8;
}
