
:root {
    --font-color:#040404;
    --background:#f8f8f8;
    --background-secondary:#f3f3f3;
    --primary:#14313f;
    --accent-color:#383292;
    --border:1px solid rgb(233, 233, 233);
    --border-dark:1px solid rgb(209, 209, 209);
    --shadow:10px 10px 10px rgb(238, 238, 238);
    --fs-xsm:0.8rem;
    --fs-sm:0.9rem;
    --fs-md:1rem;
    --fs-lg:1.3rem;
    --fs-xlg:1.7rem;
    --fs-xxlg:2.1rem;
    --container:10rem;
}
@font-face {
    font-family: mainAR;
    src: url('./Assets/fonts/static/Vazirmatn-Regular.ttf');
}
@font-face {
    font-family: mainARBold;
    src: url('./Assets/fonts/static/Vazirmatn-Bold.ttf');
}
@media(max-width:1200px) and (min-height:600px){
    :root {
        --container:5rem;
    }
}
@media(max-width:768px){
    :root{
        --container:2rem
    }
}
@media(max-width:600px){
    :root{
        --container:1rem
    }
}
html {
    scroll-behavior: smooth;
}
* {
    margin:0;
    padding:0;
    box-sizing:border-box;
}
body {
    background-color: var(--background);
    font-family: mainAR;
    color: var(--font-color);
}
a {
    text-decoration: none;
    color: var(--font-color);
}

button {
    font-family: mainAR;
}

::selection {
    background-color: var(--accent-color);
}

::-webkit-scrollbar {
    width: 4px;
    background-color: var(--background);
}
::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 2px;
}
